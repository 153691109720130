.speed_border {
    position: absolute;
    right: 0;
    margin-top: 50px;
    margin-right: 100px;
    background-color: transparent;
    padding: 5px;
    z-index: 1000;
    border-radius: 10px;
    cursor: pointer;
}
