/*
 * Project: OKIT.VCM
 * 
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 * 
 * Diese Software ist urheberrechtlich geschützt.
 */

.sidebar_radar {
  animation: openSideBar_radar 200ms ease-in;
  float: "right";
  background: #fff;
  position: relative;
  right: 0;
  width: 21rem;
  z-index: 1000;
  height: 87vh;
  margin-top: 10px;
  border-radius: 5px;
  cursor: default;

}

.sidebar_radar.removeSideBar_radar {
  animation: closeSideBar_radar 500ms ease-out;
}

@keyframes openSideBar_radar {
  0% {
    width: 0rem;
  }

  100% {
    width: 20rem;
  }
}

@keyframes closeSideBar_radar {
  0% {
    width: 20rem;
  }

  100% {
    width: 0rem;
  }
}


.radar_div1 {
  position: absolute;
  right: 0;
  margin-top: 205px;
  margin-right: 12px;
  background-color: white;
  padding: 5px;
  z-index: 1000;
  border-radius: 10px;
  cursor: pointer;
}

.input_error {
  border: 2px solid rgb(232, 61, 61);
  border-radius: 5px;
}

.input_error2 {
  border: 2px solid rgb(232, 61, 61);
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

}

.custom-div-icon {
  width: 30px;
  height: 30px;
  margin: -15px 0 0 -15px;
  border-radius: 50%;
  cursor: pointer;
}

.marker-pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: red;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -15px 0 0 -15px;
}
