.rti--input {
    width: 100% !important;
}

.css-kpm0tx-MuiButtonBase-root-MuiButton-root {
    text-transform: none !important;

}

.css-1wvake5 {
    width: fit-content !important;
}

.css-1wvake5.ps-collapsed {
    width: 80px !important;
    min-width: 80px !important;
}



.mainWidth {
    width: "";
}

.backArrow {
    height: ""
}

.mainWidthTires {
    width: "";
}

@media (min-width: 280px) {
    .backArrow {
        height: 55vh
    }
}

@media (min-width: 360px) {
    .backArrow {
        height: 51vh
    }
}

@media (min-width: 370px) {
    .backArrow {
        height: 57vh
    }
}

@media (min-width: 390px) {
    .backArrow {
        height: 64vh
    }

    .mainWidthTires {
        width: 47vh;
    }

}

@media (min-width: 400px) {
    .backArrow {
        height: 67vh
    }
}

@media (min-width: 414px) {
    .backArrow {
        height: 61vh
    }
}

@media (min-width: 540px) {
    .backArrow {
        height: 57vh
    }

    .mainWidthTires {
        width: 50vh;
    }
}

@media (min-width: 600px) {

    .mainWidthTires {
        width: 50vh;
    }
}

@media (min-width: 710px) {

    .mainWidthTires {
        width: 87vh;
    }
}

@media (min-width: 760px) {
    .mainWidth {
        width: 80vh;
    }

    .mainWidthTires {
        width: 200vh;
    }

    .backArrow {
        height: 75vh
    }

    .mainWidthTires {
        width: 65vh;
    }
}

@media (min-width: 768px) {
    .backArrow {
        height: 72vh
    }

    .mainWidthTires {
        width: 65vh;
    }
}

@media (min-width: 800px) {
    .backArrow {
        height: 74vh
    }

    .mainWidthTires {
        width: 64vh;
    }
}

@media (min-width: 910px) {

    .mainWidthTires {
        width: 120vh;
    }
}

@media (min-width: 1000px) {
    .mainWidth {
        width: 100vh;
    }

    .backArrow {
        height: 74vh
    }

    .mainWidthTires {
        width: 130vh;
    }

}

@media (min-width: 1100px) {
    .mainWidthTires {
        width: 145vh;
    }

}

@media (min-width: 1200px) {
    .mainWidthTires {
        width: 154vh;
    }
}

@media (min-width: 1300px) {
    .mainWidth {
        width: 150vh;
    }

    .backArrow {
        height: 74vh
    }

    .mainWidthTires {
        width: 167vh;
    }

}

@media (min-width: 1400px) {
    .mainWidthTires {
        width: 177vh;
    }
}

@media (min-width: 1500px) {
    .mainWidthTires {
        width: 190vh;
    }
}

@media (min-width: 1600px) {
    .mainWidthTires {
        width: 200vh;
    }
}

@media (min-width: 1700px) {
    .backArrow {
        height: 64vh
    }

    .mainWidthTires {
        width: 200vh;
    }
}

@media (min-width: 1800px) {
    .mainWidth {
        width: 180vh;
    }

    .backArrow {
        height: 74vh
    }

    .mainWidthTires {
        width: 150vh;
    }
}

@media (min-width: 2000px) {
    .mainWidth {
        width: 200vh;
    }

    .backArrow {
        height: 74vh
    }

    .mainWidthTires {
        width: 180vh;
    }
}