.App {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
}


::-webkit-scrollbar {
    width: 0.1em;
    background-color: transparent;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: transparent;
}

/* Add this to your CSS styles */

.navbar-list {
    width: 80%;
    text-align: center;
}

.navbar-line {
    border: 1px solid white;
    /* Set the desired line style */
    margin-top: 5px;
    /* Adjust the margin as needed */
    width: 100%;
}

li {
    list-style-type: none;
}