/*
 * Project: OKIT.VCM
 * 
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 * 
 * Diese Software ist urheberrechtlich gesch├╝tzt.
 */
.sidebar {
    animation: openSideBar 200ms ease-in;
    background: #fff;
    position: relative;
    left: 0;
    width: 20rem;
    z-index: 1000;
    border-radius: 5px;
    cursor: default;

}

.cardlist {
    overflow-y: scroll;
}

.sidebar.removeSideBar {
    animation: closeSideBar 500ms ease-out;
}

.cardlist::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.cardlist::-webkit-scrollbar-thumb {
    background: red;
}


.sidebar_cover {
    position: relative;
}

.sidebar_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

@keyframes openSideBar {
    0% {
        width: 0rem;
    }

    100% {
        width: 20rem;
    }
}

@keyframes closeSideBar {
    100% {
        width: 0rem;
    }

    0% {
        width: 20rem;
    }


}

.sideBar_open {
    margin-top: 10px;
    margin-left: 15px;
    z-index: 1000;
    position: absolute;
    cursor: pointer;
}


.dropdown-box {
    border: 1px solid #6c757d;
    border-radius: 7px;
    position: absolute;
    width: 100%;
    background: white;
    padding: 5px;
}