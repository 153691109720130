/*
 * Project: OKIT.VCM
 * 
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 * 
 * Diese Software ist urheberrechtlich gesch├╝tzt.
 */
 .cardstyle{
    text-decoration: none;

}

.cards:hover{
    background: rgb(195, 191, 191);
}

