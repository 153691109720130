/*
 * Project: OKIT.VCM
 * 
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 * 
 * Diese Software ist urheberrechtlich geschützt.
 */

.sidebar_analytic {
  animation: openSideBar_analytic 200ms ease-in;
  float: "right";
  background: #fff;
  position: relative;
  right: 0;
  width: 21rem;
  z-index: 1000;
  height: 94vh;
  margin-top: 10px;
  border-radius: 5px;
  cursor: default;

}

.sidebar_analytic.removeSideBar_analytic {
  animation: closeSideBar_analytic 500ms ease-out;
}

@keyframes openSideBar_analytic {
  0% {
    width: 0rem;
  }

  100% {
    width: 20rem;
  }
}

@keyframes closeSideBar_analytic {
  0% {
    width: 20rem;
  }

  100% {
    width: 0rem;
  }
}


.input_error {
  border: 2px solid rgb(232, 61, 61);
  border-radius: 5px;
}

.input_error2 {
  border: 2px solid rgb(232, 61, 61);
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

}



.inputDate {
  display: block;
  width: 80%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.inputDate2 {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.radio {
  display: block;
  width: auto;
  padding: 0.7rem 0.7rem;
  border: 1px solid #ced4da;
}

.analytic_div1 {
  position: absolute;
  right: 0;
  margin-top: 160px;
  margin-right: 12px;
  background-color: white;
  padding: 5px;
  z-index: 1000;
  border-radius: 10px;
  cursor: pointer;
}


.trace_div1 {
  position: absolute;
  right: 0;
  margin-top: 80px;
  margin-right: 12px;
  background-color: white;
  padding: 5px;
  z-index: 1000;
  border-radius: 10px;
  cursor: pointer;
}

.break_div1 {
  position: absolute;
  right: 0;
  margin-top: 120px;
  margin-right: 12px;
  background-color: white;
  padding: 5px;
  z-index: 1000;
  border-radius: 10px;
  cursor: pointer;
}

.topspeed_div1 {
  position: absolute;
  right: 0;
  margin-top: 160px;
  margin-right: 12px;
  background-color: white;
  padding: 5px;
  z-index: 1000;
  border-radius: 10px;
  cursor: pointer;
}