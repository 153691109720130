.navbar_top {
    background-color: #8c8cf0;
}

#vcm_title {
    color: white;
    font-size: 24px
}

.form_style {
    width: 100%;
    justify-content: right
}

#title_powerd {
    width: 100%;
    text-align: center;
    margin-top: 8px;
}

#dropdown-basic::after {
    border-top-color: white !important;
    width: 10px;
    height: 4px
}

#dropdown_menu {
    left: -110px;
    z-index: 9999
}

#dropdown_toggle {
    background-color: transparent;
    border: black
}

#dropdown_toggle::after {
    border-top-color: white !important;
    width: 10px;
    height: 4px
}


.select_cp {
    position: relative;
}

.select_cp .MuiSvgIcon-root {
    color: white;
    top: 4px;
}

#navbar_mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%
}

.navbar_a {
    text-decoration: none;
    color: white;
    display: block;
    /*border: 2px solid #6f6fe7;*/
    padding: 5px;
    border-radius: 5px;
    transition: background-color 0.3s ease
}
