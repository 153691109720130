/*
 * Project: OKIT.VCM
 * 
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 * 
 * Diese Software ist urheberrechtlich geschützt.
 */
.leaflet-container {
    height: 100vh;
}





.leaflet-geosearch-bar form {
    position: absolute;
    width: 100%;
    border-radius: 8px;

}

.custom-map-container .leaflet-control-layers {
    
    left: -550px !important;    /* Set custom left alignment */
  }
  
.highlighted-marker {
    /* Add your styles to highlight the marker, e.g., change the marker's color */
    /* Example: */
    background: red;
    border: 20px solid black;
}

.cluster-icon-error {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background-color: rgba(198, 20, 20, 0.605);
    transform: translate(-25%, -25%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 1.2rem;
    color: rgb(0, 0, 0)
}

.cluster-icon-warning {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background-color: rgb(207, 161, 10);
    transform: translate(-25%, -25%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 1.2rem;
    color: rgb(0, 0, 0)
}

.cluster-icon-good {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background-color: rgb(87, 230, 87);
    transform: translate(-25%, -25%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 1.2rem;
    color: rgb(0, 0, 0)
}

.cluster-icon-noaxle {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background-color: #8c8cf0;
    transform: translate(-25%, -25%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 1.2rem;
    color: rgb(0, 0, 0)
}


.wrapper {
    height: 100%
}

.reload_btn {
    position: absolute;
    right: 0;
    top: 80px;
    margin-right: 12px;
    background-color: white;
    padding: 5px;
    z-index: 1000;
    border-radius: 10px;
    cursor: pointer;
}

.reset_pos_btn {
    position: absolute;
    right: 0;
    top: 120px;
    margin-right: 12px;
    background-color: white;
    padding: 5px;
    z-index: 999;
    border-radius: 10px;
    cursor: pointer;
}

.leaflet-control-scale {
    top: -30px
}
