/*
 * Project: OKIT.VCM
 * 
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 * 
 * Diese Software ist urheberrechtlich gesch├╝tzt.
 */

.value {
  font-size: 50px;
  margin-left: 20px;
  transition: all 0.5s;
}
/* Add this to your CSS file or within a <style> tag in your component file */

.custom-pagination .page-item .page-link {
  color: #8c8cf0;
}

.custom-pagination .page-item.active .page-link,
.custom-pagination .page-item .page-link:hover,
.custom-pagination .page-item .page-link:focus,
.custom-pagination .page-item .page-link:active {
  background-color: #8c8cf0;
  border-color: #8c8cf0;
  color: white; /* Optional: to change the text color */
}

.custom-pagination .page-item.disabled .page-link {
  background-color: #8c8cf0;
  border-color: #8c8cf0;
  color: white; /* Optional: to change the text color */
}

.blur {
  filter: blur(5px);
}
.switch-handle {
  position: relative;
  margin: 0 auto;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 100%;
  width: 0px;
  border-width: 0 1px;
  background-color: #fff;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.text {
  margin-left: 20px;
  font-size: 24px;
  font-weight: bold;
}

.arrowStyle {
  margin-left: 0px;
  margin-top: 40px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid gray;
}

.lineStyle {
  grid-column: 3/4;
  height: 30px;
  margin-top: 80px;
  /* transform: rotate(90deg); */
  background-color: transparent;
}

.horizontalLineStyle {
  color: gray;
  grid-column: 3;
  width: 300%;
  align-self: center;
  margin-left: -220px;
}

.horizontalLineStyleV2 {
  color: gray;
  grid-column: 1;
  width: 480%;
  align-self: center;
}

.widthTires {
  width: 100%;
}
@media (max-width: 2600px) {
  .lineStyle {
    width: 220px;
  }

  .horizontalLineStyle {
    width: 250%;
    margin-left: -320px;
  }
}

@media (max-width: 2565px) {
  .lineStyle {
    width: 220px;
  }

  .horizontalLineStyle {
    width: 250%;
    margin-left: -320px;
  }
}

@media (max-width: 2400px) {
  .lineStyle {
    width: 220px;
  }

  .horizontalLineStyle {
    width: 250%;
    margin-left: -300px;
  }
}

@media (max-width: 2250px) {
  .lineStyle {
    width: 220px;
  }

  .horizontalLineStyle {
    width: 250%;
    margin-left: -280px;
  }
}

@media (max-width: 2100px) {
  .lineStyle {
    width: 220px;
  }

  .horizontalLineStyle {
    width: 250%;
    margin-left: -250px;
  }
}

@media (max-width: 1990px) {
  .lineStyle {
    width: 220px;
  }

  .horizontalLineStyle {
    width: 250%;
    margin-left: -225px;
  }
}

@media (max-width: 1590px) {
  .lineStyle {
    width: 220px;
  }

  .horizontalLineStyle {
    width: 250%;
    margin-left: -180px;
  }
}

@media (max-width: 1400px) {
  .lineStyle {
    width: 220px;
    margin-left: -12px;
  }

  .horizontalLineStyle {
    width: 250%;
    margin-left: -120px;
  }
}

@media (max-width: 1202px) {
  .lineStyle {
    width: 220px;
    margin-left: -11px;
  }
}

@media (max-width: 1199px) {
  .lineStyle {
    width: 220px;
    margin-left: -28px;
  }

  .horizontalLineStyle {
    width: 250%;
    margin-left: -150px;
  }
}

@media (max-width: 1194px) {
  .lineStyle {
    width: 220px;
    margin-left: -29px;
  }

  .horizontalLineStyle {
    width: 300%;
    margin-left: -150px;
  }
}

@media (max-width: 993px) {
  .lineStyle {
    width: 220px;
    margin-left: -29px;
  }
}

@media (max-width: 991px) {
  .lineStyle {
    width: 220px;
    margin-left: -50px;
  }

  .horizontalLineStyle {
    width: 300%;
    margin-left: -100px;
  }
}

@media (max-width: 990px) {
  .lineStyle {
    width: 220px;
    margin-left: -9px;
  }

  .horizontalLineStyle {
    width: 250%;
    margin-left: -150px;
  }
}

@media (max-width: 769px) {
  .lineStyle {
    width: 220px;
    margin-left: -9px;
  }

  .horizontalLineStyle {
    width: 250%;
  }
}

@media (max-width: 767px) {
  .lineStyle {
    width: 220px;
    margin-left: -35px;
  }

  .horizontalLineStyle {
    width: 250%;
    margin-left: -120px;
  }
}

@media (max-width: 575px) {
  .lineStyle {
    width: 220px;
    margin-left: -31px;
  }
}

@media (max-width: 546px) {
  .lineStyle {
    width: 220px;
    margin-left: -35px;
  }
}

@media (max-width: 542px) {
  .lineStyle {
    width: 220px;
    margin-left: -35px;
  }
}

@media (max-width: 511px) {
  .lineStyle {
    width: 220px;
    margin-left: -40px;
  }
}

@media (max-width: 501px) {
  .lineStyle {
    width: 220px;
    margin-left: -42px;
  }
}

@media (max-width: 480px) {
  .lineStyle {
    width: 220px;
    margin-left: -45px;
  }
}

@media (max-width: 470px) {
  .lineStyle {
    width: 220px;
    margin-left: -48px;
  }
}

@media (max-width: 436px) {
  .lineStyle {
    width: 220px;
    margin-left: -50px;
  }

  .horizontalLineStyle {
    width: 250%;
    margin-left: -80px;
  }
}

@media (max-width: 425px) {
  .lineStyle {
    width: 220px;
    margin-left: -54px;
  }
}

@media (max-width: 412px) {
  .lineStyle {
    width: 220px;
    margin-left: -55px;
  }
}

@media (max-width: 394px) {
  .lineStyle {
    width: 220px;
    margin-left: -58px;
  }

  .horizontalLineStyle {
    width: 250%;
    margin-left: -80px;
  }
}

@media (max-width: 392px) {
  .lineStyle {
    width: 220px;
    margin-left: -58px;
  }
  .widthTires {
    width: 80%;
  }
}

@media (max-width: 376px) {
  .lineStyle {
    width: 220px;
    margin-left: -60px;
  }

  .horizontalLineStyle {
    width: 250%;
    margin-left: -100px;
  }
}

@media (max-width: 366px) {
  .lineStyle {
    width: 220px;
    margin-left: -62px;
  }

  .horizontalLineStyle {
    width: 250%;
    margin-left: -80px;
  }
}

@media (max-width: 346px) {
  .lineStyle {
    width: 220px;
    margin-left: -65px;
  }
}

@media (max-width: 282px) {
  .lineStyle {
    width: 220px;
    margin-left: -75px;
  }

  .horizontalLineStyle {
    width: 250%;
    margin-left: -60px;
  }
}

.rwt__tablist[aria-orientation="vertical"]
  .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right: 3px solid #8c8cf0 !important;
}

/* styles.css */
.side-nav {
  transform: translateX(-100%);
}

.side-nav.open {
  transform: translateX(0);
  transition: transform 0.3s ease;
}
/* Hide side navigation on mobile */
@media only screen and (max-width: 992px) {
  .side-nav {
    transform: translateX(-100%);
  }

  .side-nav.open {
    transform: translateX(0);
    transition: transform 0.3s ease;
  }
}

/* Make TabPanel content take 100% of the screen on mobile */
@media only screen and (max-width: 992px) {
  .rwt__tabpanel {
    width: 100%;
  }
}

/* Menu icon styles */
.menu-icon {
  /*display: none;*/
  cursor: pointer;
}

.menu-icon .bar {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 4px 0;
}

.deviceName {
  background-color: white; /* White background */
  color: black; /* Black text color */
  font-weight: bold; /* Bold text */
  padding: 10px 20px; /* Vertical and horizontal padding */
  border: 2px solid black; /* Border thickness and color to resemble a plate frame */
  border-radius: 5px; /* Rounded corners to look like a plate */
  text-align: center; /* Center the text */
  font-size: 18px; /* Adjust font size for readability */
  letter-spacing: 2px; /* Space out letters a bit for a plate look */
  margin: 10px auto; /* Center plate in container and add vertical space */
}














.axleTire {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 7px;
  position: relative; 
  
}

.tireContainer {
  display: flex;
  flex-direction: row;
  
}

.twinTireContainer {
  display: flex;
  flex-direction: row;
  margin-right: 1px; /* Reduced space between twin tires */
}

.spacer {
  width: 500px;
}

.placeholder {
  width: 50px;
  height: 50px;
}

.grayLine {
  position: absolute;
  top: 50%; /* Center line vertically within the axle */
  height: 2px;
  background-color: gray;
  width: 20%; /* Span the width between left and right tires */
  z-index: -1; /* Place line behind tires */
  left: 300px;
}

.tire {
  border-width: 4px;
  border-style: solid;
  border-radius: 10px;
  padding: 10px;
  position: relative;
  background: "#e5e5e5";
  width: "100px";
  height: "130px";
  display: "flex";
  align-items: "center";
  justify-content: "center";
  border-radius: "25px";
  cursor: "pointer";
  z-index: "100";
}

.textValue {
  font-size: 18px;
}


